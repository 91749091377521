.employee-allote-container {
    width: 100%;
    margin: 10px 0px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: auto;
    background: #fff;
    max-height: 600px;
    position: relative;
}

/* Table Styles */
.employee-allote-table {
    width: 100%;
    border-collapse: collapse;
}

/* Sticky Header */
.employee-allote-sticky-header {
    position: sticky;
    top: 0;
    background: #f5f5f5;
    /* Same background for header and search */
    z-index: 10;
}

.employee-allote-table th,
.employee-allote-table td {
    padding: 12px;
    text-align: left;
}

.employee-allote-table tr {
    border-bottom: 1px solid #ddd;
}

.employee-allote-table tr:last-child {
    border-bottom: none;
}

.employee-allote-checkbox-header,
.employee-allote-checkbox-cell {
    width: 5%;
    text-align: center;
}

.employee-allote-photo-header,
.employee-allote-photo-cell {
    width: 10%;
    text-align: center;
}

.employee-allote-photo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

/* Column width adjustments */
.employee-allote-name-header,
.employee-allote-name-cell {
    width: 20%;
}

.employee-allote-id-header,
.employee-allote-id-cell {
    width: 20%;
    color: black;
}

.employee-allote-days-header,
.employee-allote-days-cell {
    width: 20%;
    color: black;
}


.employee-allote-leaves-header,
.employee-allote-leaves-header {
    width: 20%;
    color: black;
}

/* Search Row with Sticky Position */
.employee-allote-search-row th {
    background: #f5f5f5;
    padding: 8px;
    position: sticky;
    top: 40px;
    /* Adjust based on header height */
    z-index: 9;
}

/* Search Input Wrapper */
.employee-allote-search-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.employee-allote-search-input {
    width: 100%;
    padding: 8px 30px 8px 10px;
    /* Left padding for text, right padding for icon */
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    font-size: 14px;
}

.employee-allote-search-input:focus {
    border-color: #007bff;
}

/* Search Icon */
.employee-allote-search-icon {
    position: absolute;
    right: 10px;
    font-size: 14px;
    color: #999;
}