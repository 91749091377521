.nav_sidebar{
    height: 100vh;
    width: 78px;
    padding: 0px 9px;
    z-index: 99;
    background: white;
    transition: all .5s ease;
    position: fixed;
    top: 0px;
    left: 0px; 
} 

.nav_sidebar:hover{
    width: 250px;
}
.nav_sidebar .nav_logodetails{
    height: 60px;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: .5rem; 
}
.nav_sidebar .nav_logodetails .nav_logo_name{
    color: #29bffe;
    font-size: 25px;
    margin-right: 5px;
    font-weight: 600;
    transition: all .1s ease;
}

.nav_sidebar:hover .nav_logodetails .nav_logo_name{
    font-size: 30px;
}
.nav_logo_name_t{
    color: #ff9900;
}
.logoGreen{
    background: linear-gradient(to bottom, #4299d7, #16a150);
    color: #ffff !important;
}
.postypeSales{ 
    padding: 0.6rem 1rem 0.6rem !important;
    margin: 0.1rem .3rem .2rem .2rem !important;
}
.postype{
   margin:  0.1rem .1rem .2rem .3rem  !important;
   padding: 0.6rem 0.7rem 0.6rem !important;
}
.nav_sidebar i.bx , .nav_sidebar i.fa-solid, .nav_sidebar i.fa-regular,
.nav_sidebar i.fa-brands, .nav_sidebar i svg {
    /* Apply linear gradient to text color */
    background: linear-gradient(to right, #1978BC, #07DE5F);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
 
.nav_sidebar i svg{
    color: #313450;
    
    height: 4.063;
    line-height: 60px;
    min-width: 50px;
    font-size: 1.563rem;
    text-align: center;
}
.nav_sidebar i{
    color: #313450;
    
    height: 4.063;
    line-height: 60px;
    min-width: 50px;
    font-size: 1.563rem;
    text-align: center;
}


.nav-list{
   padding-left: 0;
    margin-top: 20px;
    height: 100%;
}
.nav-list li{
    position: relative;
    margin: 0.1rem .1rem;
    list-style: none;
    cursor: pointer;
}



.nav-list li div{
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  text-decoration: none; 
  position: relative;
  transition: all 0.5s ease;
  z-index: 25; 
}
.nav-list li a, .nav-list li button{
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    text-decoration: none;
    /* background-color: black; */
    position: relative;
    transition: all 0.5s ease;
    z-index: 25;
    border: 0px;
  
  }
 
  .nav-list li div::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scaleX(0);
    background-image: linear-gradient( to right, rgba(92, 173, 169, 0.3), rgba(163, 230, 149, 0.3));
    background-size: 100% 100%; /* Ensures the gradient covers the entire element */
    border-radius: 5px;
    transition: transform 0.3s ease-in-out;
    transform-origin: left;
    z-index: -2;
}
.nav-list li a::after, .nav-list li .button::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scaleX(0);
    background-image: linear-gradient( to right, rgba(92, 173, 169, 0.3), rgba(163, 230, 149, 0.3));
    background-size: 100% 100%;
    border-radius: 5px;
    transition: transform 0.3s ease-in-out;
    transform-origin: left;
    z-index: -2;
}
.nav-list li div:hover::after{
    transform: scaleX(1);
    color: whitesmoke;
}
.nav-list li a:hover::after{
    transform: scaleX(1);
    color: whitesmoke;
}
 
.nav_sidebar:hover .nav_link_name,
.nav_sidebar:hover .nav_link_name_sub{
    opacity: 1;
}

.nav-list .nav_link_name{
    
    color: #686e72;
    font-size: 15px;
    font-weight: 400;
    white-space: nowrap;
    pointer-events: auto;
    transition: all 0.4s ease;
    opacity: 0;
}
 

.nav-list li div:hover .nav_link_name,
.nav-list li div:hover i, .nav-list li div:hover svg{
    transition: all 0.5s ease; 
    color: #1b405a;
}
.nav-list li a:hover .nav_link_name,
.nav-list li a:hover i, .nav-list li a:hover svg{
    transition: all 0.5s ease; 
    color: "#978BC";
}
.sub_nav-list{
    margin: 0px 20px;
    position: absolute;
    top:0;
    padding-left: 0;
    width: 111%;
    left:200px;
    background-color: white;
    /* overflow-y: auto; */
}


.nav_sidebar .profile{
    position: fixed;
    height: 60px;
    width: 250px;
    left: 0;
    bottom:30px; 
    transition: all .5s ease;
}
.nav_sidebar .profile .profile_details{
    display: flex;
   
    align-items: center;
    flex-wrap: nowrap;
}
 
.nav_sidebar:hover img{
    margin-left: 5px;
}
.nav_sidebar .nav_logodetails img{
    height: 30%;
    width: 100%;
    /* margin: 0 auto; */

    /* margin-right: 10px; */
    transition: all 1s ease;
}

.nav_sidebar:hover .nav_logodetails img{
    height: 85%;
    width: 70%;
    margin: 0 auto;
}
.nav_sidebar .userimg{
    height: 45px;
    width: 45px;
    margin-left: 15px; 
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
    transition: all 1s ease;
}
.nav_sidebar:hover .profile_name,
.nav_sidebar:hover .desingnations{
    opacity: 1;
}
.nav_sidebar .profile_name,
.nav_sidebar .desingnations{
    font-size: 15px;
    font-weight: 500;
    color: #313450;
    white-space: nowrap;
    opacity: 0;
   
}

.nav_sidebar .desingnations{
    font-size: 12px;
}
.nav_sidebar:hover #log_out{
    opacity: 1;
}
.nav_sidebar #log_out{
   position: absolute;
   top: 50%;
   right: 0;
   transform: translateY(-55%);
   color:#313450 ;
   width: 50px;
   height: 60px;
   line-height: 60px;
   cursor: pointer;
   transition: all 2s ease;
   opacity: 0;
}
.toolBar{
    position: relative;
    top: 0px;
    left: 0px;
    width: 100vw;
    height:5rem;
    background: #fff; 
    
}
.user-profile-data {
    position: absolute;
    right: 2rem;
    margin: 2rem 0;
    width: 3rem;
    height: 3rem;
    border: 1px solid black;
    border-radius: 50px;
  }
/*  */
@media (max-height:855px) {

    .nav_sidebar i{
       
        height: 3.8rem !important;
        line-height: 45px;
        
    }
    /* .nav_sidebar i svg{
        height: 3.8rem !important;
    } */
    
}

@media (max-height:815px) {

    .nav_sidebar i{
       
        height: 3rem !important;
        line-height: 45px;
        
    }
    /* .nav_sidebar i svg{
        height: 3rem !important;
    }
     */
}

