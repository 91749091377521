.profile-container {
    height: 100%;
    /* Make sure it takes full height */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.upper-half {
    flex: 1;
    /* Takes half the space */
    display: flex;
    justify-content: center;
    align-items: center;
}

.lower-half {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.profile-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
    width: 100%;
    padding: 10px;
}

.profile-image {
    width: 35%;
    height: 50%;
    /* background-color: #ddd; */
    border-radius: 8px;
    display: flex;
    margin-right: 25px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.profile-image img {
    width: 97%;
    height: 100%;
    object-fit: cover;
}

.profile-details {
    margin-top: 5px;
}

.profile-name {
    font-size: 24px;
    font-weight: bold;
    color: #949494;
    margin-bottom: 2px;
    display: block;
    word-break: break-word;
    white-space: normal;
    text-align: left;
    width: 100%;
    position: relative;
    padding-bottom: 2px;
}

.profile-name::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #ccc;
    position: absolute;
}


.profile-id {
    font-size: 15px;
    color: gray;
}

.profile-underline {
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin-top: 8px;
}


.button-container {
    display: flex;
    gap: 16px;
    /* Space between buttons */
}

.break-button {
    display: flex;
    align-items: center;
    gap: 8px;
    /* Space between icon and text */
    padding: 8px 16px;
    background-color: #84888A;
    /* Gray color */
    color: white;
    border: none;
    border-radius: 9999px;
    /* Fully rounded */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: not-allowed;
    opacity: 100%;
    font-size: 14px;
}

.button-icon {
    font-size: 16px;
    /* Adjust icon size */
}

.break-timer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.break-timer-container {
    width: 246.06px;
    height: 213px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.progress-circle {
    width: 100%;
    height: 100%;
}


.CircularProgressbar-text {
    transform: translate(-35px, 5px) !important;
    /* Adjust position */
    font-size: 15px !important;
    /* Adjust text size */
    font-weight: bold;
    fill: black !important;
    /* Ensure text color */
}


.attendance-buttons-cancel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* padding: 20px 50px; */
}

.attendance-buttons-save {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: row-reverse;
    /* padding: 20px 50px; */
}

.attendance-cancel {
    padding: 10px 60px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 999px;
    /* Fully rounded */
    cursor: pointer;
    transition: all 0.3s ease;
    min-width: 120px;
    text-align: center;
}

.attendance-save {
    padding: 10px 63px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 999px;
    /* Fully rounded */
    cursor: pointer;
    transition: all 0.3s ease;
    min-width: 120px;
    text-align: center;
}

/* Gradient for Cancel Button */
.attendance-cancel {
    background: linear-gradient(to right, #1D9D8A, #1B79BB);
}

/* Gradient for Save Button */
.attendance-save {
    background: linear-gradient(to right, #1D9D8A, #1B79BB);
}

/* Hover Effect */
.attendance-cancel:hover,
.attendance-save:hover {
    opacity: 0.8;
}

.break-container {
    position: absolute;
    bottom: 30px;
    /* Adjust to align properly */
    left: 50%;
    transform: translateX(-50%);
}

.break-text {
    font-size: 14px;
    color: #b0b0b0;
    font-weight: 400;
    letter-spacing: 0.5px;
    font-family: "Arial", sans-serif;
    margin: 0;
    cursor: pointer;
}

.reset-icon {
    position: absolute;
    color: #979393;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.2s ease;
    left: 30px;
    top: 26px;
}

.reset-icon:hover {
    color: #1D9D8A;
    transform: scale(1.1);
}


.delete-icon-button{
    margin-top: 20px;
    color: #ff5656d9;
    font-size: 15px;
    cursor: pointer;
}