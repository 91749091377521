.itemMaster_Wrap{
    position: fixed;
    top: 0;
    left: 78px;
    
    width: 100%;
    height: 100%;
}

.itemMaster_Top{
    width: 95%;
    height: 8%;
    background: white;
}

.itemMastertable{
    width: 95%;
    height: 70%;
 
    
    overflow-y: auto;
}


.parentofwrap_Drag_drop{
    width: 50%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px black solid;
}

.wrap_Drag_drop.ul.li{
    text-decoration: none;
}