.status-content {
    display: flex;
    /* Remove quotes */
    align-items: center;
    /* Remove quotes */
    background-color: #f8f9fa;
    /* Remove quotes */
    border: 1px solid #ddd;
    /* Remove quotes */
    border-radius: 16px;
    /* Remove quotes */
    padding: 0.3rem 0.8rem;
    /* Remove quotes */
    cursor: pointer;
    /* Remove quotes */
    font-size: 14px;
    /* Remove quotes */
    margin-right: 16px;
    /* Remove quotes */
    position: relative;
    /* Remove quotes */
}

.status-content span {
    margin-right: 8px;
    /* Remove quotes */
    color: #555
        /* Remove quotes */
}

.status-content select {
    border: none;
    background: transparent;
    font-size: 14px;
    color: #555;
    outline: none;
    cursor: pointer;
    appearance: none;
    padding: 0.2rem 0.5rem;
    font-weight: 800;
}

.view-button {
    display: flex;
    align-items: center;
    background-color: #f8f9fa;
    border: none;
    color: #000;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    margin-right: 16px;
}

.clear-filters {
    cursor: pointer;
    margin-top: 0.5rem;
}

.clear-filters i {
    color: #5CADA9;
    /* Specific color for the icon */
}

.table-header {
    border: hidden !important;
    /* border-style: none !important; */
    font-size: 1rem;
    text-align: center;
    overflow: hidden;
}

.table-header-content {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-header-text {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sort-icon {
    font-size: 1rem;
    margin-left: 0.5rem;
    vertical-align: middle;
}

.table-cell {
    border-style: none;
    padding: 0rem 0.5rem;
    text-align: left;
}

.date-input-group {
    position: relative;
    cursor: pointer;
}

.clear-date-icon {
    position: relative;
    right: 1rem;
    cursor: pointer;
}

.date-picker-popup {
    position: absolute;
    z-index: 1;
    width: 25rem;
    display: none;
    /* Adjust as necessary */
    left: 0rem;
    top: 2.2rem;
    /* Adjust as necessary */
}


.number-filters-popup {
    position: absolute;
    z-index: 1;
    width: 15rem;
    /* Adjust as necessary */
    display: none;
    /* Adjust as necessary */
}

.row-cell {
    text-decoration: none;
    cursor: pointer;
    letter-spacing: .2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 20px;
}

.row-cell-2 {
    letter-spacing: .2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 20px;

}

/* .stann-report-table-options{
    margin: .6rem .2rem 0rem .6rem !important;
 
} */
.stann-report-table-options-icon {

    margin: .6rem .2rem 0rem .8rem !important;

}

.stann-report-table-options {
    position: fixed;
    top: 3.5rem;
    right: 4.2rem;
    z-index: 1000;
    width: 10rem;
}

.stann-report-table-options .list-group li:hover {
    background: #0d6efd;
    color: #FFFF;
    cursor: pointer;
}

.exportTableIcon {
    scale: 2;
    padding: .1rem;
}

.select-all-header {
    width: 60px;
    text-align: center;
}
.select-all-header-option {
    /* width: 60px; */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
}
.checkbox-column {
    width: 40px;
    text-align: center;
}

.checkbox-column input,
.select-all-header input {
    cursor: pointer;
}