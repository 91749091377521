/* Custom modal container */
.email-template-custom-modal .modal-dialog {
    max-width:90%;
    height:95%;
  }
.email-template-fields{
  width: 95%;
  height:70%;
  overflow-y: hidden;

}
.email-template-fields ul{
  height: 90%;
  overflow-y: scroll;
}

.email-template-fields .copy-button{
  font-size: 1.5rem;
  cursor: pointer;
}


@media screen and (max-width: 1370px) {

  .email-template-custom-modal .modal-dialog {
    height:100%;
    overflow-y: scroll
  }

}