.PermissionsFrom .Permissions_warp{
    display: flex;
    justify-content: space-evenly;

    width: 90%;
}


.PermissionsFrom .PermissionsList{ 
    list-style-type: none; 
    width: 80% !important;
    
}
.PermissionsFrom .PermissionsList li .modelname{
    flex: .7;

}
 
.PermissionsFrom .PermissionsList li .selecteValue {
    cursor: pointer;
    flex: 3;
    width: 80%;
    margin: 0rem 1rem;
    border-bottom: 2px solid rgb(206, 201, 201) !important;
    transition: border-bottom-color 1s ease; 
}

.PermissionsFrom .PermissionsList li .selecteValue.active {
    border-bottom: 2px solid #5cada9 !important; 
}


.PermissionsFrom .PermissionsList li{
    padding: 5px;
    
}

.PermissionsFrom   .sub_PermissionsList{
 
    position: absolute;
    top: -5%;
     right: -12rem; 
    list-style-type: none;  
    width: 20%; 
    margin: 1rem;
    padding: 2rem;
    
}

/* .PermissionsFrom   .sub_PermissionsList li{
    padding: .5rem;
} */


.profile_permission{
    display: grid;
    column-gap: 50px;
    grid-template-columns: auto auto auto;
    padding: 10px;
  }
  
  .profile_permission > div {
    background-color:#fff;
    border: 1px solid transparent;
    padding: 20px;
    font-size: 30px; 
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-top: 1rem;
  }

.permission-header{
    display: flex;
    justify-content: space-between;
}
  