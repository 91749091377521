.table-wrapper {
    width: 100%;
    overflow-x: auto;
}

.table-target-container {
    overflow: auto;
}

.target-table {
    border-collapse: collapse;
    width: 100%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin: 10px 0;
    border-radius: 8px;
}

.target-table th,
.target-table td {
    display: table-cell;
    padding: 8px;
    text-align: center;
    color: rgb(51, 51, 51);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.target-table th {
    background-color: #f9f9f9;
    font-weight: bold;
    height: 3rem;
    text-align: center;
    padding: 8px;
    color: #333;
    border-bottom: 1px solid #ddd;
}

.target-table td {
    display: table-cell;
    padding: 8px;
    text-align: center;
    color: rgb(51, 51, 51);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;;
    font-size: 14px !important;
}

.target-table .fw-bold {
    font-weight: bold;
}

.target-table .action-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.no-record {
    color: #999;
    font-style: italic;
}