.activities-container {
    display: flex;
    flex-direction: column;
    max-height: 75vh;
    overflow-x: hidden;
}

.activities-list {
    overflow-x: hidden;
    overflow-y: auto;
}

.activities-list-2 {
    overflow-x: hidden;
    position: relative;
    overflow-y: auto;
}

.planedList {
    margin: 1rem;
    /* min-height: fit-content; */
    max-height: 35vh !important;
    width: 97%;
    list-style-type: none;
    /* overflow-y: auto;  */
}





.CallLogList {
    margin: 1rem;
    /* height: 71vh !important; */
    width: 97%;
    list-style-type: none;
    /* overflow-y: auto;  */

}

.completedList {
    margin: 1rem;
    /* min-height: fit-content; */
    /* max-height: 35vh !important; */
    width: 97%;
    list-style-type: none;
    overflow-y: auto;
}

.completedList li:hover,
.planedList li:hover,
.CallLogList li:hover {
    background-color: #5cada9;
    color: #fff;
    cursor: pointer;
}


.Activites .emptyList {
    width: 100%;
    height: 100%;
    background: url('../../img/Empty.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    transition: transform 0.3s ease;

}

.Activites .emptyList {
    height: 30vh;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
}


.status {
    background-color: #5cada9;
    color: #fff;
    /* padding: 1px 3px; */

}

.fab {
    position: fixed;
    bottom: 40px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: rgb(92, 173, 169);
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.2s ease-in-out;
}

.fab:hover {
    background-color: rgb(79, 145, 141);
    ;
    transform: scale(1.1);
}

.container {
    max-width: 900px;
    margin: auto;
}

.toggle-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background: #f9f9f9; */
    padding: 10px 15px;
    /* border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); */
    margin-bottom: 10px;
}

.toggle-label {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    margin-bottom: .5rem;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 22px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 20px;
}

.slider::before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #4f9490;
}

input:checked+.slider::before {
    transform: translateX(18px);
}

/* Container for the picker */
.date-time-picker-container {
    position: relative;
    width: 100%;
    max-width: 350px;
}

/* Wrapper for input and icon */
.input-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding-left: 8px;
    padding-bottom: .5rem;
    background: white;
    cursor: pointer;
    position: relative;
}

/* Input field styling */
.input-wrapper input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 14px;
    padding-top: 10px;
    /* Adjust spacing for label */
    cursor: pointer;
}

/* Label positioning */
.input-wrapper label {
    position: absolute;
    top: -10px;
    left: 12px;
    font-size: .8rem;
    color: black;
    background: white;
    padding: 0 4px;
    font-weight: normal;
}

.tabs-container {
    display: flex;
    width: 100%;
    overflow-x: auto;
    /* Enables horizontal scrolling if needed */
    scrollbar-width: none;
    /* Hide scrollbar for Firefox */
    -ms-overflow-style: none;
    /* Hide scrollbar for IE/Edge */
}

.tabs-container::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari */
}

.tabs {
    display: flex;
    gap: 65px;
    padding: 10px;
    white-space: nowrap;
    /* Prevents text wrapping */
}

.tab {
    position: relative;
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    padding: 8px 12px;
    font-size: 14px;
    color: #666;
    transition: color 0.3s ease-in-out;
}

/* Active Tab - Blue underline */
.tab.active {
    color: rgb(92, 173, 169);
    font-weight: bold;
}

.tab.active::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: rgb(92, 173, 169);
    border-radius: 2px;
}

/* Hover effect - Show underline */
.tab:not(.active)::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 50%;
    width: 0%;
    height: 3px;
    background-color: rgb(92, 173, 169);
    transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

.tab:not(.active):hover::after {
    width: 100%;
    left: 0;
}

.activity-container-planned {
    padding: 20px;
    max-width: 600px;
    margin: auto;
    max-height: 400px;
    overflow-y: auto;
}

.activity-container-completed {
    padding: 20px;
    max-width: 600px;
    margin: auto;
    max-height: 400px;
    overflow-y: auto;
}

.activity-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    margin-left: 11px;
}

.activity-item {
    display: flex;
    align-items: flex-start;
    /* Aligns icon and text at the top */
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    gap: 12px;
}

.activity-info {
    flex-grow: 1;
    /* Allows text to take up remaining space */
    display: flex;
    flex-direction: column;
}

.activity-subject {
    font-weight: bold;
    color: #2768c5;
    display: block;
}

.activity-outcome {
    color: gray;
    font-size: 0.9em;
}

.activity-datetime {
    font-size: 12px;
    color: #777;
    margin-top: 6px;
}

.activity-date {
    color: #b63637;
    font-weight: bold;
    font-size: 12px;
    text-align: right;
    min-width: 120px;
    /* Ensures proper width */
    /* display: flex;
    align-items: center;
    justify-content: flex-end; */
}

.no-activities {
    text-align: center;
    color: gray;
    font-style: italic;
}

.no-notes {
    text-align: center;
    color: gray;
    font-style: italic;
    margin-top: 50px;
}

.notes-wrapper {
    padding: 20px;
    max-width: 600px;
    /* margin: auto; */
    height: 100%;
    max-height: 678px;
    overflow-y: auto;
}


.notes-header {
    font-size: 1.5em;
    margin-bottom: 10px;
    margin-left: 11px;
}

.notes-icon {
    width: 24px;
    height: 24px;
}

/* .note-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
} */
.note-item {
    padding: 10px;
    /* Adds space inside each note item */
    border-bottom: 1px solid #ddd;
    /* Adds a subtle border between notes */
    cursor: pointer;
    /* Shows pointer cursor on hover */
    /* display: flex; */
    justify-content: space-between;
    /* Aligns content and icons separately */
    align-items: center;
    /* Centers content vertically */
}

.note-content-wrapper {
    display: flex;
    /* Aligns note icon and text horizontally */
    align-items: center;
    /* Centers content vertically */
    gap: 10px;
    /* Adds spacing between icon and text */
    flex-grow: 1;
    /* Allows text to expand while keeping icons right-aligned */
}

/* .note-display {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
} */
.note-display {
    display: flex;
    /* Enables flex layout */
    justify-content: space-between;
    /* Keeps content on the left and icons on the right */
    align-items: center;
    /* Centers items vertically */
    width: 100%;
    /* Ensures full width of container */
}

.note-display-icon {
    flex-shrink: 0;
    /* Prevents icon from resizing */
    width: 25px;
    /* Sets fixed width */
    height: 25px;
    /* Sets fixed height */
    display: flex;
    /* Centers the icon */
    align-items: center;
    /* Aligns icon vertically */
    justify-content: center;
    /* Centers icon horizontally */
}

.note-text {
    display: flex;
    /* Enables flexible layout */
    flex-direction: column;
    /* Aligns text and meta information in column */
}

.note-content {
    font-weight: bold;
    color: #333;
    /* Dark gray color for readability */
    text-align: left;
    /* Aligns text to the left */
}

/* .note-hover-icons {
    display: flex;
    gap: 8px;
    opacity: 0;
    transition: opacity 0.3s;
    flex-direction: column;
} */
.note-hover-icons {
    display: flex;
    gap: 8px;
    opacity: 0;
    transition: opacity 0.3s;
    /* flex-direction: column; */
    /* align-items: flex-end; */
    /* align-content: flex-end; */
    /* flex-wrap: wrap; */
    justify-content: flex-end;
}

.note-item:hover .note-hover-icons {
    opacity: 1;
}


/* Metadata container for Created By & Time */
.note-meta {
    font-size: 12px;
    /* Smaller font for metadata */
    color: gray;
    /* Light gray for secondary info */
    display: flex;
    flex-direction: column;
    /* Stack items vertically */
    gap: 3px;
    /* Small gap between username and timestamp */
}

/* Timestamp should appear below the Created By */
.note-time {
    font-size: 12px;
    color: gray;
}

/* Icon Colors */
.email-icon {
    color: #007bff;
    /* Blue for email */
}

.call-icon {
    color: #28a745;
    /* Green for calls */
}

.default-icon {
    color: #6c757d;
    /* Gray for default */
}

.task-icon {
    color: #c50876;
    /* Orange for tasks */
}

/* .activity-icon {
    font-size: 24px;
    margin-right: 10px;
}
.activity-img-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
} */

.activity-icon,
.activity-img-icon {
    flex-shrink: 0;
    /* Prevents icons from shrinking */
    font-size: 26px;
    /* Adjust icon size */
    width: 25px;
    /* Ensures consistent width for all icons */
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.history-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.history-header {
    display: flex;
    justify-content: space-between;
}

.history-content {
    display: flex;
    align-items: center;
    gap: 12px;
}

.history-icon {
    width: 18px;
    height: 18px;
    margin-top: 5px;
}

.history-details {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #333;
    line-height: 1.4;
}

.history-user {
    font-weight: bold;
    color: #2768c5;
    margin-top: 5px;
}

.no-history {
    text-align: center;
    font-size: 14px;
    color: #999;
    margin-top: 50px;
}

.history-time {
    font-weight: bold;
}

.history-date {
    font-weight: bold;
}

.parent-link-activity-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.parent-link-activity-label {
    font-size: 15px;
    color: grey;
    font-weight: 500;
    margin-right: 5px;
    white-space: nowrap;
}

.parent-link-activity-content {
    display: flex;
    align-items: center;
    gap: 5px;
    /* Ensures space between text and icon */
}

.parent-link-activity-text {
    font-size: 15px;
    color: #000000;
    font-weight: 600;
    cursor: pointer;
    white-space: nowrap;
}

.external-link-icon {
    font-size: 12px;
    color: grey;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    margin-bottom: 16px;
}

.external-link-icon:hover {
    color: #0056b3;
}


@keyframes blink {
    0% {
        opacity: 1;
        color: #000000;
        background-color: #ffeb3b;
    }

    50% {
        opacity: 0;
        color: #000000;
    }

    100% {
        opacity: 1;
        color: #000000;
        background-color: #ffeb3b;
    }
}

.neonText {
    color: green;
    animation: blink 1s infinite;
}

@media (max-width: 576px) {
    .toggle-container {
        flex-direction: column;
        text-align: center;
    }
}

/* Reduce gap and padding for smaller screens */
@media (max-width: 768px) {
    .tabs {
        gap: 10px;
        /* Reduce space between tabs */
        padding: 8px;
        overflow-x: auto;
    }

    .tab {
        font-size: 12px;
        /* Reduce font size */
        padding: 6px 10px;
    }

    .toggle-container {
        flex-direction: row;
        justify-content: space-between;
    }
}

@media (max-width: 480px) {
    .tabs {
        gap: 8px;
        /* Further reduce spacing */
        padding: 5px;
        justify-content: flex-start;
        /* Align tabs to the left */
    }

    .tab {
        font-size: 11px;
        padding: 5px 8px;
    }
}

@media (max-width:1365px) {
    .tabs {
        gap: 106px;
        /* Further reduce spacing */
    }

    .tab {
        font-size: 11px;
        padding: 5px 8px;
    }

    .fab {
        bottom: 10px;
    }
}