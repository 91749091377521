.hierarchical_tree_view_body{
  background-color: #dae2e6;
}

.tree {
  width: 300%;
  height: 100%;
  text-align: center;
  overflow: auto;
  padding: 20px;

}

.tree ul {
  width: max-content;
  position: relative;
  padding-top: 30px;
  transition: all 0.5s;
}

.tree li {
  display: inline-table;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding-top: 30px;
  transition: all 0.5s;
}

.tree li::before,
.tree li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  width: 51%;
  height: 30px;
  border-top: 1px solid #ccc;
  border-color: orange;
}

.tree li::after {
  left: 50%;
  right: auto;
  border-left: 1px solid #ccc;
  border-color: orange;
}

.tree li:only-child::after,
.tree li:only-child::before {
  content: '';
  display: none;
}

.tree li:only-child {
  padding-top: 0;
}

.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}

.tree li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 25px 0 0;
  border-color: orange;
}

.tree li:first-child::after {
  border-radius: 25px 0 0 0;
  border-color: orange;
}

.tree ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 30px;
  border-color: orange;
}

.tree li div {
  display: inline-grid;
  text-decoration: none;
  transition: 0.5s;
  background-color: white;
}


.tree li div:hover,
.tree li div:hover+ul li div {
  color: #000;
  background: #c8e4f8;
  border: 1px solid #94a0b4;
}

.tree div {
  border: 1px solid #ccc;
  border-radius: 13px;
  color: #666;
  width: 10.5rem;
  height: 12.5rem;
  margin: 0 .5rem;
  /* padding: 13px !important; */
  text-transform: uppercase;
  letter-spacing: normal;
  font-weight: 500;
}

.tree div span {
  font-size: .8rem !important;
}

.tree li div:hover+ul li::after,
.tree li div:hover+ul li::before,
.tree li div:hover+ul::before,
.tree li div:hover+ul ul::before {
  content: '';
  border-color: #94a0b4;
}

.currentModel {
  background: #78dee4;

}


.tree-container {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #DAE2E6;
}

.tree-node {
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  padding: 10px;
  text-align: center;
  width: 180px;
  margin: 10px;
}

.node-label {
  font-weight: bold;
  text-transform: uppercase;
  padding: 0px 10px;
  border-radius: 6px 6px 0 0;
  color: white;
  display: flex;
  margin-bottom: 8px;
  height: 32px;
  align-items: center;
  justify-content: center;
}


/* Background colors for different labels */
.lead-label {
  background: #e74c3c; /* Red */
}

.sales-order-label {
  background: #6c5ce7; /* Purple */
}

.quotation-label {
  background: #f1c40f; /* Yellow */
}

.enquiry-label{
  background: #00d335;  
}


.tree-node span {
  display: block;
  margin: 1px 0;
}