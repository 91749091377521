/* Apply blur effect to the backdrop */
.blur-backdrop {
    backdrop-filter: blur(30px);
    background-color: rgba(0, 0, 0, 0.7);
}

/* Ensure the modal is properly styled */
.custom-modal .modal-content {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.85);
    /* Slight transparency */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}


.report-container {
    height: 500px;
}

.report-container-expand {
    height: 70%;
}

.detail-container {
    margin-left: 25px;
}

.property-container {
    margin-top: 30px;
}


.container {
    margin-top: 26px;
}

.container-expand {
    margin-top: 82px;
}

.stepper-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    margin-left: 5%;
}

.stepper-container-expand {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    margin-left: 23%;

}

.edit-report {
    background-color: #e8f4fb;
    color: #5a9bd4;
    padding: 8px 12px;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    font-weight: bold;
}

.stepper {
    display: flex;
    align-items: center;
    gap: 48px;
    position: relative;
    flex-grow: 1;
}

.step-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 37px;
}

.step {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 15px;
    border-radius: 20px;
    background: #eee;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background 2s;
    z-index: 2;
    position: relative;
    width: 140px;
    text-wrap:nowrap;
}
.step.enquiry{
    width: 200px;
}

.step .full_true {
    width: 130px;
}

/* @keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    10% { background-position: 10% 50%; }
    25% { background-position: 25% 50%; }
    30% { background-position: 30% 50%; }
    50% { background-position: 50% 50%; }
    75% { background-position: 75% 50%; }
    90% { background-position: 90% 50%; }
    100% { background-position: 100% 50%; }
} */
@keyframes backgroundAnimation {
    0% {
        background-color: #dfe2e2;
        /* Start with white */
        mask-position: 100% 0;
        /* Mask fully to the right */
    }

    33% {
        background-color: #1D9D8A;
        /* Transition to #1D9D8A */
        mask-position: 66% 0;
        /* Mask moves partially to the left */
    }

    66% {
        background-color: #0083B0;
        /* Transition to #0083B0 */
        mask-position: 33% 0;
        /* Mask moves further to the left */
    }

    100% {
        background: linear-gradient(90deg, #1D9D8A, #0083B0);
        /* Final gradient */
        mask-position: 0% 0;
        /* Mask fully to the left */
    }
}

.step.active {
    background-color: linear-gradient(90deg, white, #0083B0, #1D9D8A);
    ;
    /* Initial background color */
    color: white;
    transform: scale(1.1);
    /* Mask to create the opacity effect */
    mask: linear-gradient(90deg, white, #0083B0, #1D9D8A);
    /* Transparent on the left, white on the right */
    mask-size: 200% 100%;
    /* Double the width to allow sliding */
    animation: backgroundAnimation 2s ease-in-out forwards;
    /* Animate the background and mask */
}

.step-circle {
    padding: 8px 15px;
    border-radius: 50%;
    background: #ccc;
    position: absolute;
    left: -10px;
    /* Adjust position */
    transition: left 0.3s ease-in-out, background 0.3s ease-in-out;
}

.step.active .step-circle {
    background: linear-gradient(90deg, #0083B0, #00B4DB);
}

.line {
    height: 4px;
    width: 40px;
    background: #ccc;
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    transition: background 0.3s ease-in-out, width 0.3s ease-in-out;
}

.line.completed {
    background: linear-gradient(90deg, #0083B0, #00B4DB);
    width: 100px;
}

.close-btn {
    background: #eef2f5;
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
    cursor: pointer;
    font-weight: bold;
}

.accordion-container {
    height: 250px;
    position: relative;
}

.accordion-container-expand {
    height: 600px;
    position: relative;
}

.drag-drop-container {
    display: flex;
    width: 100%;
    gap: 20px;
    padding: 10px 20px;
}

.drag-drop-container-expand {
    display: flex;
    width: 100%;
    height: 600px;
    gap: 20px;
    padding: 10px 20px;
}


.left-section {
    width: 30%;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 10px;
    overflow-y: auto;
    max-height: 400px;
}

.left-section-expand {
    width: 20%;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 10px;
    overflow-y: auto;
    max-height: 600px;
}

.left-section::-webkit-scrollbar {
    display: none;
    /* Hides the scrollbar */
}

.left-section-expand::-webkit-scrollbar {
    display: none;
    /* Hides the scrollbar */
}

/* Hide scrollbar for IE and Edge */
.left-section {
    -ms-overflow-style: none;
    /* IE and Edge */
}

/* Hide scrollbar for Firefox */
.left-section {
    scrollbar-width: none;
    /* Firefox */
}



.button-container-expand {
    position: fixed;
    bottom: 2rem;
    left: 1;
    width: 100%;
    margin-top: 0;
    /* Remove top margin to make it stick to the bottom */
    padding: 20px 0;
    /* Add some padding to ensure it’s not flush to the bottom */
    background-color: #fff;
    /* Optionally add a background color for better visibility */
    z-index: 10;
    /* Ensure it stays on top of other elements */
}

.button-container {
    padding: 20px 0;
    position: relative;
    /* Keep it inside the parent container */
    bottom: 0;
    left: 0;
    width: 100%;
}

.buttons-left {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    /* margin-left: 15px; */
}

.buttons-left-expand {
    margin-left: 15px;
}

.buttons-right {
    /* display: flex; */
    align-items: center;
    justify-content: flex-end;
    /* Aligns the Next button to the right */
    flex-grow: 1;
    /* margin-right: 40px; */
    margin-right: 15px;
}

.buttons-right-expand {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    margin-right: 40px;
}

.button-save {
    align-items: center;
    flex-grow: 1;
}

/* .previous-button {
    margin-left: 328px;
    background: linear-gradient(to right, #0F9D58, #0083B0);
    color: white;
    padding: 10px 25px;
    border: none;
    cursor: pointer;
    border-radius: 30px;
    font-weight: bold;
    transition: all 0.3s;
} */
.previous-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(to right, #0F9D58, #0083B0);
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding: 10px 10px;
    border: none;
    cursor: pointer;
    border-radius: 30px;
    position: relative;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    /* margin-right: 20px; */
    right: 0;
}

.next-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(to right, #0F9D58, #0083B0);
    /* Gradient */
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding: 10px 10px;
    border: none;
    cursor: pointer;
    border-radius: 30px;
    position: relative;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    /* margin-right: 20px; */
    right: 0;
}

.next-button :disabled {
    cursor: not-allowed;
}

.save-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(to right, #0F9D58, #0083B0);
    /* Gradient */
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding: 11px 48px;
    border: none;
    cursor: pointer;
    border-radius: 30px;
    position: relative;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    /* margin-right: 20px; */
    right: 0;
}

/* Disabled state */
.buttons-left button:disabled,
.buttons-right button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

/* Hover effect */
.buttons-left button:not(:disabled):hover,
.buttons-right button:not(:disabled):hover {
    opacity: 0.9;
}

/* Arrow UI */
.arrow-container-right {
    display: flex;
    align-items: center;
    margin-left: 15px;
    position: relative;
}

.arrow-container-left {
    display: flex;
    align-items: center;
    margin-right: 15px;
    position: relative;
}

.button-line {
    width: 75px;
    height: 2px;
    background: white;
}

.button-circle {
    width: 22px;
    height: 23px;
    border-radius: 50%;
    border: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow-left {
    font-size: 14px;
    color: white;
    font-weight: bold;
}

.arrow-right {
    font-size: 14px;
    color: white;
    font-weight: bold;
}

.accordion-item {
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    overflow-y: auto;
    max-height: 400px;
}

.accordion-item-expand {
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    overflow-y: auto;
    max-height: 600px;
}

.search-bar-expand {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
    align-items: center;
    background-color: #EBF5F5;
    padding: 6px;
    position: relative;
    /* border-radius: 10px; */
}

.search-bar {
    background: #EBF5F5;
    padding: 0.75rem 0.5rem;
    margin-bottom: 10px;
    position: relative;
    /* border-radius: 10px; */
}

.search-info {
    position: absolute;
    right: 0;
    margin-top: -0.5rem;
    margin-right: 0.5rem;
}

.search-input-container-expand {
    /* width: 100%; */
    margin-left: auto;
    position: relative;
    display: flex;
    align-items: center;
    /* margin-top: 1.5rem; */
}

.search-input-container {
    width: 100%;
    margin-left: auto;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

}

.search-bar input {
    width: 100%;
    padding: 7px 5px;
    border: 1px solid #ccc;
    border-radius: 16px;
    padding-right: 30px;
}

.search-bar-expand input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 16px;
    padding-right: 30px;
}

.search-bar .bi-search {
    position: absolute;
    right: 10px;
    color: #424242;
    /* Icon color */
    cursor: pointer;
}

.search-bar-expand .bi-search {
    position: absolute;
    right: 10px;
    color: #424242;
    /* Icon color */
    cursor: pointer;
}

.drag-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.accordion-items {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.draggable-item {
    padding: 8px 12px;
    /* border: 1px solid #ccc; */
    /* border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff; */
    cursor: grab;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 100px;
}

/* .draggable-item:hover {
    background: #EBF5F5;
} */

.right-section {
    width: 725px;
    max-height: 400px;
}

.right-section-expand {
    width: 75%;
    max-height: 600px;
}

.right-dropped {
    width: 100%;
    height: 44px;
    /* Adjusted height to fit horizontal layout */
    padding: 10px;
    background-color: #EBF5F5;
    display: flex;
    /* Allows multiple rows if needed */
    gap: 10px;
    /* Space between items */
    align-items: center;
    /* border-radius: 10px; */
}

.drop-text {
    color: #888;
    margin-bottom: 10px;
}

.item-icon {
    font-size: 15px;
    color: #555;
    margin-right: 5px;
    font-weight: bold;
}

.no-records {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #666;
    font-size: 14px;
    font-style: italic;
    padding: 20px;
}

.expand-icon {
    padding: 5px 0;
}

.expand-icon-expand {
    position: fixed;
    bottom: 0;
    left: 1;
    width: 100%;
    margin-top: 0;
    /* Remove top margin to make it stick to the bottom */
    padding: 20px 0;
    /* Add some padding to ensure it’s not flush to the bottom */
    /* Optionally add a background color for better visibility */
    z-index: 10;
    /* Ensure it stays on top of other elements */
}

/* Dropped Section */
.dropped-section {
    width: 100%;
    padding: 10px;
    background: #f9f9f9;
    border-radius: 5px;
}

/* Accordion Styles */
.dropped-accordion-header {
    font-weight: bold;
    padding: 10px 16px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    gap: 15px;
    /* Adds space between icon and text */
}

.dropped-content-left-icon {
    margin-right: 1rem;
}

.dropped-accordion-content {
    margin-left: 50px;
}

/* 
.dropped-content .MuiAccordionDetails-root {
    background: #f8f8f8;
} */

.accordion-item .css-1c35hjw-MuiPaper-root-MuiAccordion-root {
    background-color: #F2F2F2 !important;
}

.accordion-item .css-pwcg7p-MuiCollapse-root {
    background-color: white !important;
}

.dropped-content .MuiPaper-root-MuiAccordion-root {
    background-color: red;
}

.dropped-content .MuiPaper-root.MuiAccordion-root.Mui-expanded {
    margin: 0;
}

.dropped-content .MuiAccordion-root.Mui-expanded {
    margin-bottom: 1px;
}

.dropped-accordion-row {
    display: flex;
    align-items: center;
    gap: 30px;
}

.dropped-accordion-row p {
    margin: 5px 0;
    white-space: nowrap;
}

.dropped-accordion-content p {
    margin: 5px 0;
}

/* Expand icon */
.dropped-accordion-header i {
    color: #878787;
    font-size: 14px;
}

/* No Records Container */
.dropped-content {
    /* padding: 15px; */
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
    font-size: 14px;
    color: #777;
    overflow: auto;
}

.dropped-content .MuiAccordion-root {
    margin: none !important;
    /* Removes box shadow */
}


.table-container {
    width: 100%;
    height: 400px;
    overflow-x: auto;
}

.table-container-expand {
    width: 100%;
    height: 600px;
    overflow-x: auto;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table th,
.custom-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.custom-table th {
    background: #ffffff;
    cursor: grab;
    white-space: nowrap;
    height: 60px;
    position: relative;
    padding-right: 30px;
}

.custom-table .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-1086bdv-MuiPaper-root-MuiAccordion-root {
    box-shadow: none;
}

.accordion-table {
    width: 100%;
}

.accordion-table td {
    border-right: none;
    border-left: none;
}

.draggable-column {
    display: table-cell;
    position: relative;
}

.drag-handle {
    margin-right: 8px;
    cursor: grab;
}

.th-header {
    width: 2rem !important;
}

.close-icon {
    position: absolute;
    right: 10px;
    /* Positions it at the right end */
    top: 50%;
    transform: translateY(-50%);
    /* Centers it vertically */
    cursor: pointer;
    font-size: 14px;
    color: rgb(77, 77, 77);
    z-index: 2;
}

.blue-text {
    color: #0065ff;
    cursor: pointer;
    /* Optional: Makes it look clickable */
}

.cal-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
}

.cal-table th,
.cal-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.cal-table thead {
    background-color: #e5e7eb;
}

.cal-table .name-column {
    width: 50%;
}

.cal-table .expand-icon {
    margin-right: 8px;
    font-size: 14px;
    cursor: pointer;
}

.cal-table .blue-bg {
    background-color: #dbeafe;
}

.total-row {
    font-weight: bold;
    background-color: #dbeafe;
}

.filter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
}

.filter-container .row {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    /* Adjust based on your needs */
    gap: 20px;
}


.delete-icon {
    cursor: pointer;
    transition: opacity 0.3s;
    left: 0;
}

.add-condition-button {
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: #a1a0a0;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    padding: 5px;
    transition: color 0.3s;
    margin: 20px auto 0;
    gap: 12px;
}

.add-condition-button .plus-icon {
    font-size: 18px;
    color: black;
    transition: color 0.3s;
}

.add-condition-button:hover,
.add-condition-button:hover .plus-icon {
    color: #1D9C8C;
    /* Change this to match the hover color in the image */
}


.close-button {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    /* Circular shape */
    border: none;
    background-color: #EBF5F5;
    /* Light, slightly transparent background */
    font-size: 16px;
    font-weight: bold;
    color: black;
    /* "X" color */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.3s ease;
}

.close-button:hover {
    background-color: #b6d4d4;
    /* Darker on hover */
}

.chart-container {
    display: flex;
    gap: 20px;
    padding: 20px;
}

.chart-container-expand {
    display: flex;
    gap: 20px;
    padding: 20px;
    height: 100%;
}

.right-section-chart {
    flex-grow: 1;
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 480px;
    width: 100%;
}

.right-section-chart img {
    max-width: 55%;
    height: auto;
}

.left-section-chart {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
}


.chart-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    color: #6c757d;
    font-size: 16px;
    font-weight: 500;
    opacity: 0.9;
}

.chart-text img {
    height: auto;
}

.chart-text p {
    margin: 0;
    font-size: 14px;
    color: #5a5a5a;
}

.chart-type-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 300px;
    margin: auto;
}

.chart-select {
    width: 100%;
}

.chart-type {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.chart-type-options {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.chart-type-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 80px;
    text-align: center;
    font-size: 14px;
}

.chart-type-option:hover,
.chart-type-option.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

.chart-type-option.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

.chart-option {
    display: flex;
    align-items: center;
    gap: 10px;
}

.remove-botton-container {
    display: flex;
    flex-direction: column;
    height: 30%;
    justify-content: space-around;
}

.remove-button {
    background-color: #f5f5f5;
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.remove-button:hover {
    background-color: #e0e0e0;
}

.remove-button:disabled {
    background-color: #f5f5f5;
    color: #333;
    cursor: not-allowed;
}


.edit-chart-container {
    background: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 50%;
    position: relative;
    overflow-y: auto;
}

.edit-chart-container::-webkit-scrollbar {
    width: 0px;
    /* Hides scrollbar in WebKit-based browsers (Chrome, Safari) */
}

.edit-chart-container {
    -ms-overflow-style: none;
    /* Hides scrollbar in Internet Explorer 10+ */
    scrollbar-width: none;
    /* Hides scrollbar in Firefox */
}

.edit-chart-container .header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.edit_chart_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}

.edit_chart_content .edit_chart {
    width: 100%;
    height: 100%;
}

.edit_chart_content strong {
    font-size: 16px;
    color: black;
    margin-bottom: 15px;
}

.edit-chart-container .pie-chart-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    overflow: hidden;
    box-sizing: border-box;
}

.edit-chart-container .full-screen-btn {
    background: #f5f5f5;
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    transition: background-color 0.3s ease;
}

.edit-chart-container .full-screen-btn:hover {
    background-color: #e0e0e0;
}

/* Added styles for fullscreen mode */
.edit-chart-container.fullscreen {
    position: fixed;
    top: 0;
    left: 100%;
    /* Initially off-screen */
    width: 100vw;
    height: 100vh;
    background: white;
    z-index: 1000;
    padding: 20px;
    transition: left 0.5s ease-in-out;
    /* Smooth slide-in effect */
}

/* When active, slide into view */
.edit-chart-container.fullscreen.active {
    left: 0;
}

/* Adjust pie chart for full screen mode */
.edit-chart-container.fullscreen .pie-chart-wrapper {
    width: 100%;
    height: 80vh;
    /* Occupy most of the screen */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modify button to indicate exiting fullscreen */
.edit-chart-container.fullscreen .full-screen-btn {
    background-color: #f5f5f5;
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.date-calender {
    position: absolute;
    z-index: 1;
    width: 25rem;
    display: none;
    left: 10rem;
    top: 2.2rem;
}

.date-calender .rdrCalendarWrapper.rdrDateRangeWrapper {
    width: 100%;
}

.filter-condition-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 18%;
}

.filter-condition-container-expand {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 8%;
}

.edit-table-container {
    overflow-x: auto;
    /* Enables horizontal scrolling */
    white-space: nowrap;
    /* Prevents text from wrapping */
    background: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.edit-table-container-table-header {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
}

.edit-table-container .record-count {
    background: #e0e0e0;
    border-radius: 50%;
    padding: 5px 10px;
    margin-left: 8px;
    font-size: 14px;
}

.edit-table {
    width: 100%;
    border-collapse: collapse;
}

.edit-table td {
    padding: 10px;
    border-bottom: 1px solid #eee;
    white-space: normal;
    /* Allows text to wrap */
    word-wrap: break-word;
    max-width: 200px;
    /* Adjust as needed */
}

.edit-table tbody tr:nth-child(odd) {
    background-color: #f5f5f5;
    /* Light Grey for Odd Rows */
}

.edit-table tbody tr:nth-child(even) {
    background-color: #ffffff;
    /* White for Even Rows */
}

.report-accordion-container {
    width: 100%;
}

.report-accordion-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    /* Ensures equal column width */
}

.report-accordion-table th,
.report-accordion-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #eee;
    width: auto;
    /* Ensures dynamic width matching */
    vertical-align: middle;
}

/* Ensure that the parent table headers align properly */
.edit-table th {
    text-align: left;
    font-weight: bold;
    padding: 10px;
    width: 8%;
}


/* Syncing widths of header and accordion data */
/* .edit-table th:nth-child(n),
.report-accordion-table td:nth-child(n) {
    width: auto;
} */

/*  */
/* .grouped-row .css-1086bdv-MuiPaper-root-MuiAccordion-roo{
    box-shadow: none;
} */

.grouped-row-data {
    background-color: white;
}

.accordion-summary-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.calculated-summary-container {
    display: flex;
    align-items: center;
    gap: 15px;
    /* Adds spacing between each calculated item */
    margin-right: 10px;
    /* Ensures it doesn’t touch the dropdown icon */
}

.calculated-summary {
    font-size: 14px;
    color: #666;
    /* Slightly lighter text for clarity */
}

.export-menu {
    position: absolute;
    right: 0;
    margin-top: 4.5rem;
    margin-right: 0.5rem;
    width: 11rem;
    /* Equivalent to w-40 */
    background-color: white;
    border: 1px solid #e5e7eb;
    /* Equivalent to border-gray-200 */
    border-radius: 0.375rem;
    /* Equivalent to rounded-md */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Equivalent to shadow-lg */
    z-index: 10;
}

.export-menu button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    padding: 11.5px 9px;
    /* Equivalent to px-4 py-2 */
    font-size: 0.875rem;
    /* Equivalent to text-sm */
    color: #4b5563;
    /* Equivalent to text-gray-700 */
    background: none;
    border: none;
    cursor: pointer;
}

.export-menu button:hover {
    background-color: #f3f4f6;
    /* Equivalent to hover:bg-gray-100 */
}

.export-menu button svg {
    width: 1.25rem;
    /* Equivalent to h-5 w-5 */
    height: 1.25rem;
}


/* For tablets and smaller desktops */
@media (max-width: 1360px) {
    .container {
        margin-top: 10px;
    }

    .stepper-container {
        margin-left: 19%;
    }

    .step-wrapper {
        margin-left: -13px;
    }

    .stepper {
        gap: 24px;
    }

    .step {
        padding: 6px 6px;
        font-size: 10px;
        width: 86px;
        margin-left: 50px;
    }

    .line {
        width: 30px;
    }

    .line.completed {
        width: 100px;
    }

    .edit-report {
        padding: 6px 6px;
        font-size: 10px;
    }

    .next-button {
        padding: 6px;
        font-size: 10px;
    }

    .previous-button {
        padding: 6px;
        font-size: 10px;
    }

    .save-button {
        padding: 9px 62px;
        font-size: 10px;
    }

    .arrow-right,
    .arrow-left {
        font-size: 10px;
    }

    .button-circle {
        height: 20px;
        width: 20px;
    }

    .property-container {
        margin-top: 9px;
    }

    .report-container {
        height: 100%;
    }

    .accordion-container {
        height: 100%;
    }

    .accordion-container-expand {
        height: 90%;
    }

    .left-section {
        max-height: 350px;
    }

    .custom-table th {
        height: 50px;
    }

    .filter-container {
        padding: 7px;
        height: 378px;
        justify-content: flex-start;
    }

    .edit-chart-container {
        height: 80%;
    }

    .detail-container {
        height: 378px;
    }

    .button-container {
        bottom: 0;
    }

    .expand-icon {
        position: relative;
        bottom: 0;
    }

    .remove-button {
        margin-top: 2px;
    }

    .drag-drop-container {
        height: 340px;
    }

    .drag-drop-container-expand {
        height: 340px;
    }

    .table-container-expand {
        height: 340px;
    }

    .table-container {
        height: 340px;
    }

    .chart-button-container {
        margin-top: 10px;
    }

    .right-section-expand {
        max-height: 340px;
        width: 70%;
    }
}

@media (max-width: 1024px) {
    .container {
        margin-top: 10px;
    }

    .stepper-container {
        margin-left: 5%;
    }

    .step-wrapper {
        margin-left: -13px;
    }

    .stepper {
        gap: 24px;
    }

    .step {
        padding: 6px 6px;
        font-size: 10px;
        width: 86px;
        margin-left: 50px;
    }

    .line {
        width: 30px;
    }

    .line.completed {
        width: 100px;
    }

    .edit-report {
        padding: 6px 6px;
        font-size: 10px;
    }

    .next-button {
        padding: 6px;
        font-size: 10px;
    }

    .previous-button {
        padding: 6px;
        font-size: 10px;
    }

    .save-button {
        padding: 9px 29px;
        font-size: 10px;
    }

    .arrow-right,
    .arrow-left {
        font-size: 10px;
    }

    .button-circle {
        height: 20px;
        width: 20px;
    }

    .property-container {
        margin-top: 9px;
    }

    .report-container {
        height: 100%;
    }

    .accordion-container {
        height: 100%;
    }

    .accordion-container-expand {
        height: 90%;
    }

    .left-section {
        max-height: 350px;
        width: 350px;
    }

    .custom-table th {
        height: 50px;
    }

    .filter-container {
        padding: 7px;
        height: 378px;
        justify-content: flex-start;
    }

    .edit-chart-container {
        height: 80%;
    }

    .detail-container {
        height: 378px;
    }

    .button-container {
        bottom: 0;
    }

    .expand-icon {
        position: relative;
        bottom: 0;
    }

    .remove-button {
        margin-top: 2px;
    }

    .drag-drop-container {
        height: 340px;
    }

    .drag-drop-container-expand {
        height: 580px;
    }

    .table-container-expand {
        height: 580px;
    }

    .table-container {
        height: 340px;
        width: 415px;
    }

    .chart-button-container {
        margin-top: 10px;
    }

    .right-section-expand {
        max-height: 555px;
        width: 65%;
    }
    .left-section-expand {
        width: 25%;
    }
}


/* For mobile devices */
@media (max-width: 768px) {
    .stepper-container {
        margin-left: 5%;
        padding: 5px;
    }

    .stepper {
        gap: 12px;
    }

    .step-wrapper {
        margin-left: 20px;
    }

    .step {
        padding: 4px 8px;
        font-size: 10px;
    }

    .line {
        width: 20px;
    }

    .line.completed {
        width: 60px;
    }

    .buttons {
        flex-direction: column;
        gap: 5px;
    }

    .buttons button {
        padding: 6px 10px;
        font-size: 12px;
    }
}

/* For very small mobile devices */
@media (max-width: 480px) {
    .stepper-container {
        margin-left: 0;
        padding: 5px;
    }

    .stepper {
        gap: 8px;
    }

    .step-wrapper {
        margin-left: 10px;
    }

    .step {
        padding: 3px 6px;
        font-size: 8px;
    }

    .line {
        width: 15px;
    }

    .line.completed {
        width: 40px;
    }

    .buttons button {
        padding: 4px 8px;
        font-size: 10px;
    }
}