.importModel{
    position: relative;
    height: 80vh;
    background: #f8fdff;
    max-width: 100%;
}


 
.file-uploader-wrap{
    position: absolute;
    top: 20%;
    left: 5%; 
    border: 1px solid #1D9D8A;
    border-radius:1rem;
    width: 45%;
    height: 50%;
    box-shadow: -1px 1px 8px 3px rgba(0,0,0,0.07) inset;
-webkit-box-shadow: -1px 1px 8px 3px rgba(0,0,0,0.07) inset;
-moz-box-shadow: -1px 1px 8px 3px rgba(0,0,0,0.07) inset;
}
.uploadClearExcel{
    width: 2em;
    height: 2em;
    position: absolute;
    top: 3%;
    left: 93%;
    transition:.5s ease-in-out;
}
.uploadClearExcel:hover{
    color: #1D9D8A;
    
    transform:rotate(90deg) 
}
.import-upload-icon{
    width: 5em;
    height: 5em;
    position: absolute;
    top: 40%;
    left: 45%;
}
.import-conditions{
    position: absolute;
    top: 60%;
    left: 32%;
}
.import-file-name{
    position: absolute;
    top: 60%;
    left: 37%;
}
.upload-conditions{
    position: absolute;
    top: 20%;
    left: 55%;
    border-radius:1rem;
    width: 40%;
    height: 50%;
    overflow-y: scroll;
}

.import-buttons{
    width: 100%;
    position: absolute;
    top: 90%;
    left: 0;
    display: flex;
    justify-content: center;
}


.import-Validations{
    position: absolute;
    top: 30%;
    left: 35%;
    color: #0083B0;
}
.import-Verifying{
    position: absolute;
    top: 30%;
    left: 45%;
    color: #0083B0;
}
.import-excel-icon{
    color: #0083B0;
    position: absolute;
    top: 40%;
    left: 43%;
    width: 5em;
    height: 5em;
    
}

.import-excel-button-width{
    width: 20rem;
}

.file-uploader-wrap-Successfully{
    position: absolute;
    top: 20%;
    left: 30%; 
    border: 1px solid #1D9D8A;
    border-radius:1rem;
    width: 45%;
    height: 50%;
    box-shadow: -1px 1px 8px 3px rgba(0,0,0,0.07) inset;
-webkit-box-shadow: -1px 1px 8px 3px rgba(0,0,0,0.07) inset;
-moz-box-shadow: -1px 1px 8px 3px rgba(0,0,0,0.07) inset;
}


.import-Successfully-msg{
    position: absolute;
    top: 45%;
    left: 28%;
    font-size: 2rem;
    color: #1D9D8A;
}


 