/* Calendar Container */
.calendar-container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Custom Header */
.custom-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f8f9fa;
    padding: 10px 20px;
    border-radius: 8px;
    margin-bottom: 10px;
}

/* Header Buttons */
.header-button,
.view-toggle,
.settings-btn,
.add-btn {
    background: #4CAF50;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.header-button:hover,
.view-toggle:hover,
.settings-btn:hover,
.add-btn:hover {
    background: #45a049;
}

/* Navigation Buttons */
.nav-buttons {
    display: flex;
    align-items: center;
}

.nav-buttons button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    margin: 0 10px;
    color: #4CAF50;
}

.date-display {
    font-size: 16px;
    font-weight: bold;
}

/* Dropdown Styling */
.view-toggle {
    background: white;
    color: black;
    border: 1px solid #ccc;
}

.dropdown-menu {
    min-width: 120px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Modal */
.event-modal {
    width: 350px;
    margin: auto;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.modal-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.add-btn {
    background: #4CAF50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-btn {
    background: #d9534f;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}