.stepper-item {
  flex: 1;
  padding: 20px 20px 20px 40px;
  background: repeating-linear-gradient(-65deg,
      #f5f5f5,
      #f5f5f5 20px,
      #eaeaea 20px,
      #eaeaea 40px);
  margin: 0 -1px;
  clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
  position: relative;
  text-align: center;
  transition: background 0.3s ease, transform 0.3s ease;
  opacity: 0.5;
}

.stepper-item.current {
  background: #007bff;
  color: white;
  font-weight: bold;
  opacity: 1;
  /* Full opacity for the selected item */
  transform: scale(1.05);
  /* Slightly enlarge the current step */
}

.stepper-item.complete {
  background: #28a745;
  /* Darker green for completed steps */
  color: white;
  opacity: 0.5;
  /* Keep completed items dull */
}

.stepper-item:first-child {
  padding: 20px;
  clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
}

.stepper-item:last-child {
  clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.stepper-item:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  width: 20px;
  height: 2px;
  background: #e7e7e7;
  transform: translateY(-50%);
}

/* Custom colors for each step */
.stepper-item:nth-child(1) {
  background: #d4edda;
  /* Light green for the first step */
}

.stepper-item:nth-child(2) {
  background: #b3e0ff;
  /* Light blue for the second step */
}

.stepper-item:nth-child(3) {
  background: #dfcfbf;
  /* Light green for the third step */
}

.stepper-item:nth-child(4) {
  background: #e0f7fa;
  /* Light cyan for the fifth step */
}

.stepper-item:nth-child(5) {
  background: #f4e1d2;
  /* Light orange for the fourth step */
}


/* Adjust dark backgrounds for current steps */
.stepper-item.current:nth-child(1) {
  background: #28a745;
  /* Dark green for the current step */
}

.stepper-item.current:nth-child(2) {
  background: #007bff;
  /* Dark blue for the current step */
}

.stepper-item.current:nth-child(3) {
  background: #c98d56;
  /* Darker light green for the current step */
}

.stepper-item.current:nth-child(4) {
  background: #00acc1;
  /* Darker light cyan for the current step */
}

.stepper-item.current:nth-child(5) {
  background: #ffb74d;
  /* Darker light orange for the current step */
}


.confetti-container {
  position: fixed;
  /* Fixed positioning to ensure it's aligned to the viewport */
  top: 0;
  /* Align to the top of the viewport */
  right: 0;
  /* Align to the right side of the viewport */
  width: 100vw;
  /* Full width of the viewport */
  height: 100vh;
  /* Full height of the viewport */
  pointer-events: none;
  /* Ensure it doesn't block interactions */
  z-index: 9999;
  /* High z-index to ensure it appears on top of other elements */
  display: flex;
  /* Center content if needed */
  align-items: center;
  /* Center vertically */
  justify-content: center;
  /* Center horizontally */
}


.activity-counter {
  position: absolute;
  top: 90px;
  right: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  font-size: 20px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}


/* Activity count container */
.activity_count-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  justify-content: space-around;
}

/* Individual activity count item */
.activity_count-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Icon styles */
.activity_count-icon {
  font-size: 22px;
}

/* Phone call icon */
.activity_count-phone {
  color: #28a745;
}

/* Task icon */
.activity_count-task {
  color: #ff4081;
}

/* Email icon */
.activity_count-email {
  color: #007bff;
}

/* Meeting icon */
.activity_count-meeting {
  width: 24px;
  height: 24px;
}

/* Number styles */
.activity_count-number {
  font-weight: bold;
  font-size: 16px;
}
