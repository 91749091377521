.gauge-container {
    height: 255px;
    margin: auto;
}

.modal-content-container {
    display: flex;
    justify-content: space-between;
}

.left-section-summary {
    flex: 1;
    display: flex;
    justify-content: center;
}

.right-section-summary {
    flex: 1;
}

.table-wrapper-summary {
    width: 100%;
    overflow-x: auto;
    max-height: 400px;
}

.table-target-container-summary {
    overflow: auto;
}

.summary-table {
    border-collapse: collapse;
    width: 100%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin: 10px 0;
    border-radius: 8px;
}



.summary-table th,
.summary-table td {
    display: table-cell;
    padding: 8px;
    text-align: center;
    color: rgb(51, 51, 51);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.summary-table th {
    background-color: #f9f9f9;
    font-weight: bold;
    height: 3rem;
    text-align: center;
    padding: 8px;
    color: #333;
    border-bottom: 1px solid #ddd;
}

.summary-table td {
    display: table-cell;
    padding: 8px;
    text-align: center;
    color: rgb(51, 51, 51);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    ;
    font-size: 14px !important;
}

.summary-table .fw-bold {
    font-weight: bold;
}

.summary-table .action-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.no-record {
    color: #999;
    font-style: italic;
}


.profile-card-summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* text-align: center; */
    width: 100%;
    padding: 10px;
}

.profile-image-summary{
    width: 35%;
    height: 100%;
    /* background-color: #ddd; */
    border-radius: 8px;
    display: flex;
    /* margin-right: 25px; */
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.profile-image-summary img {
    width: 60%;
    height: 100%;
    object-fit: cover;
}

.profile-details-summary{
    margin-top: 5px;
}

.profile-name-summary{
    font-size: 24px;
    font-weight: bold;
    color: #949494;
    margin-bottom: 2px;
    display: block;
    word-break: break-word;
    white-space: normal;
    text-align: left;
    width: 100%;
    position: relative;
    padding-bottom: 2px;
}
.profile-name-summary::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #ccc;
    position: absolute;
}

.profile-id-summary{
    font-size: 15px;
    color: gray;
}