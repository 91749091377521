.mail-container {
  width: 100%;
  height: 725px;
  max-width: 2000px;
  margin: auto;
  padding: 20px;
  /* background: #fff; */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.mail-header-btn {
  background: white;
  width: 50px;
  margin-right: 20px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  font-weight: bold;
  cursor: pointer;
}

.mail-header-btn span:hover {
  background-color: #f1f1f1;
}

.mail-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.mail-header label {
  width: 80px;
  font-weight: bold;
}

.mail-input {
  flex: 1;
  padding: 4px 8px;
  border: none;
  width: auto;
  min-width: 120px;
  outline: none;
  background: none;
  transition: border-bottom 0.3s ease-in-out;
}

.mail-input.disabled {
  cursor: not-allowed;
}

.input-container {
  position: relative;
  width: 100%;
  height: auto;
}

.input-wrapper-mail {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  row-gap: 5px;
  max-height: 120px;
  padding: 5px;
  border-bottom: 2px solid #ccc;
  /* Base border */
  position: relative;
}

.input-wrapper-mail-bcc {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  row-gap: 5px;
  max-height: 120px;
  padding: 5px;
  border-bottom: 2px solid #ccc;
  position: relative;
  overflow-y: auto;
}

.focus-border {
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0%;
  height: 2px;
  background-image: linear-gradient(to right, #0166d1 50%, #0166d1 50%);
  background-repeat: no-repeat;
  background-size: 100% 2px;
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
  transform: translateX(-50%);
}

.selected-contacts {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 8px;
  border-radius: 4px;
  min-height: 40px;
  align-items: center;
}

.contact-chip {
  display: flex;
  align-items: center;
  background: #e0e0e0;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  margin-bottom: 5px;
  /* Fix overlapping issue */
  max-width: 100%;
}


.remove-contact {
  border: none;
  background: none;
  margin-left: 5px;
  cursor: pointer;
  font-weight: bold;
  color: rgb(180, 180, 180);
}

.suggestions-dropdown {
  position: absolute;
  top: 40px;
  left: 10px;
  width: 30%;
  height: auto;
  max-height: 280px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 10px 0;
  overflow-y: auto;
}

.suggestions-dropdown::-webkit-scrollbar {
  display: none;
}

/* .suggestions-header {
  padding: 8px 16px;
  font-weight: bold;
  color: #555;
} */
.suggestions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  color: #555;
  font-weight: bold;
}

.close-suggestions {
  align-items: center;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
}


.suggestion-item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  transition: background 0.3s;
}

.suggestion-item:hover {
  background: #f1f1f1;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  margin-right: 10px;
  margin-bottom: 12px;
}

.contact-info {
  margin-top: 3px;
  display: flex;
  flex-direction: column;
}

.contact-name {
  margin-bottom: 0px;
  font-weight: bold;
}

.contact-email {
  font-size: 12px;
  color: #777;
}




/* On focus, expand border from center */
.mail-input:focus~.focus-border {
  width: 100%;
}

.mail-dropdown {
  margin-left: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.mail-search-icon {
  margin-left: 5px;
  cursor: pointer;
}

.mail-toolbar {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
}

.mail-btn {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.mail-btn:hover {
  background: #0056b3;
}


/* Quill Editor Styling */
.mail-editor-container {
  /* border: 1px solid #ccc;
  border-radius: 4px; */
  height: 340px;
  /* background: #f9f9f9; */
  margin-top: 10px;
}

.mail-quill-editor {
  height: 250px;
  border-radius: 4px;
}

.ql-toolbar {
  border-radius: 4px 4px 0 0;
  /* background: #f4f4f4; */
}

.ql-container {
  border-radius: 0 0 4px 4px;
  min-height: 200px;
  background: #fff;
}

.mail-actions {
  display: flex;
  gap: 10px;
  margin-top: 15px;
  justify-content: flex-end;
}

.send-btn {
  display: flex;
  align-items: center;
  background: #1E88DC;
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  height: 35px;
}

.send-icon {
  font-weight: bolder;
  font-size: 20px;
  margin-right: 8px;
}

.send-btn:hover {
  background: #0056b3;
}

.cancel-btn {
  display: flex;
  align-items: center;
  background: #bbbbbb;
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  height: 35px;

}

.cancel-btn:hover {
  background: #646464;
}

.attach-mail-file-container {
  display: flex;
  align-items: center;
  gap: 15px;
  /* Space between button & file list */
}

.attach-mail-file {
  position: relative;
  display: inline-block;
}

.attach-btn {
  background: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;
}

.attach-btn:hover {
  background: #e0e0e0;
}

.attach-icon {
  font-size: 16px;
}

.hidden-file-input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
}

/* Attached files list */
.attachments-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.attachment-item {
  background: #f5f5f5;
  padding: 6px 10px;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid #ddd;
}

.attachment-item span {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.attachment-item button {
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #888;
}

.attachment-item button:hover {
  color: red;
}