.comman-table-row:hover {
  background-color: #e6f8f6 !important;
}

.CommanTable {
  border-collapse: collapse;
  width: 100%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  border-radius: 8px;
}

.CommanTable td,
.CommanTable th {
  text-align: left;
  padding: .5rem 0rem;
  border-bottom: 1px solid #ddd;
  vertical-align: middle;
  color: #333;

}


.CommanTable th {
  background-color: #f9f9f9;
  font-weight: bold;
  height: 3rem;
  text-align: center;
  padding: 8px;
  color: #333;
}

/* Save Button */
.CommanTable td .save-button {
  background-color: #fff;
  /* White background */
  color: #007bff;
  /* Primary blue text color */
  border: 2px solid #007bff;
  /* Blue border */
  border-radius: 5px;
  /* Slightly rounded corners */
  font-size: 0.8rem;
  /* Font size */
  padding: 10px 10px;
  /* Padding for better button size */
  cursor: pointer;
  /* Pointer cursor on hover */
  display: inline-flex;
  /* Align content within button */
  align-items: center;
  /* Vertically center items */
  justify-content: center;
  /* Horizontally center items */
  transition: all 0.3s ease;
  /* Smooth transition for hover effect */
}

.CommanTable td .save-button i {
  margin-left: 4px;
  /* Space between text and icon */
  font-size: 1rem;
  /* Icon size */
}

.CommanTable td .save-button:hover {
  background-color: #007bff;
  /* Blue background on hover */
  color: #fff;
  /* White text color */
  border-color: #0056b3;
  /* Darker blue border on hover */
}

/* Delete Button */
.CommanTable td .delete-button {
  background-color: #fff;
  /* White background */
  color: #dc3545;
  /* Red text color */
  border: 2px solid #dc3545;
  /* Red border */
  border-radius: 5px;
  /* Slightly rounded corners */
  font-size: 0.8rem;
  /* Font size */
  padding: 10px 10px;
  /* Padding for better button size */
  cursor: pointer;
  /* Pointer cursor on hover */
  display: inline-flex;
  /* Align content within button */
  align-items: center;
  /* Vertically center items */
  justify-content: center;
  /* Horizontally center items */
  transition: all 0.3s ease;
  /* Smooth transition for hover effect */
}

.CommanTable td .delete-button i {
  margin-left: 4px;
  /* Space between text and icon */
  font-size: 1rem;
  /* Icon size */
}

.CommanTable td .delete-button:hover {
  background-color: #dc3545;
  /* Red background on hover */
  color: #fff;
  /* White text color */
  border-color: #a71d2a;
  /* Darker red border on hover */
}

.CommanTable tbody tr {
  transition: background-color 0.2s ease-in-out;
}

.CommanTable tbody tr td {
  border-right: 1px solid #eee;
  font-size: 14px !important;
}

.comman-table-header {
  height: 3rem;
  text-align: center;
  padding: 8px;
  font-weight: bold;
  color: #333;
}

/* 
.CommanTable {
  border-collapse: collapse;
  width: 100%;
  font-family: Arial, sans-serif;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  border-radius: 8px;
  overflow: hidden;
}

.CommanTable td,
.CommanTable th {
  text-align: left;
  padding: 0.8rem;
  border-bottom: 1px solid #ddd;
  vertical-align: middle;
  font-size: 14px;
  color: #333;
}

.CommanTable th {
  background-color: #f3f4f6; 
  font-weight: bold;
  color: #222;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}

.CommanTable tbody tr {
  transition: background-color 0.2s ease-in-out;
}

.CommanTable tbody tr:nth-child(even) {
  background-color: #fafafa; 
}

.CommanTable tbody tr:hover {
  background-color: #eef7f6; 
}

.CommanTable tbody tr td {
  border-right: 1px solid #eee;
}

.CommanTable tbody tr td:last-child {
  border-right: none;
}

.CommanTable thead th {
  position: sticky;
  top: 0;
  z-index: 2;
}

.CommanTable tbody tr td[colspan] {
  text-align: center;
  font-size: 15px;
  color: #777;
  font-style: italic;
}

.comman-table-row:hover {
  background-color: #e6f8f6 !important;
} */