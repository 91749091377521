.setcontainer-width {
  width: 95%;
  margin-left: 2%;
}

.modal-fullscreen.modal-dialog {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}


.link_color {
  color: blue;
}

.import-container {
  padding-top: 5rem;
  height: 30rem;
  width: 80%;
  margin: auto;
}

.commanModelTitleColor {
  color: #5CADA9;
}

/* #5CADA9 */
.validation-container {
  padding-top: 5rem;
  height: 30rem;
  width: 70%;
  margin: auto;
}

.import-container>div {
  width: 48%;
}

.import-container>div>div {
  height: 15rem;
}

.import-container>div:nth-child(1) {
  border: 1px solid rgb(105, 101, 101);
  border-style: dashed;
  border-radius: 10px;
}

.excel-icon-circle {
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  background-color: #cdffcd;
  border: 1px solid #cdffcd;
}

.excel-icon-circle>i {
  position: relative;
  top: 3.2rem;
  left: 3.5rem;
}

.excel-import-top-container {
  width: 70%;
  margin: auto;
}

.button-upload {
  color: rgb(122, 143, 238);
}

#stockfrom {
  padding-left: 0px;
}

.dynamikTable {
  border: '1px solid gray';
  white-space: 'nowrap';
}

.cursor-pointer {
  cursor: pointer;
}

@media (min-width: 992px) {

  .setcontainer-width {
    width: 95%;
    margin-left: 5%;

  }
}

@media (min-width: 768px) {

  .setcontainer-width {
    width: 90%;
    margin-left: 2%;
  }

}

@media (max-width: 576px) {

  .setcontainer-width {

    display: none;
  }
}


@media(min-width: 576px) {}

.setcontainer-width {
  width: 75%;
  margin-left: 2%;
}



.ccard {
  width: 80%;
}

.card {
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, .125);
  border-radius: 1rem;
}
/* @media (max-width: 1400px) and (max-height: 633px) {
  .card {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      margin-top: 0.5rem;
      padding: 0.5rem 0.5rem;    
      box-shadow: none;
  }
} */

.bgc-h-secondary-l3:hover,
.bgc-secondary-l3 {
  background-color: #ebeff1 !important;
}

.h-4 {
  height: 2rem;
}

.w-4 {
  width: 2rem;
}

.d-zoom-1,
.d-zoom-2,
.d-zoom-3,
.dh-zoom-1,
.dh-zoom-2,
.dh-zoom-3 {
  transition: -webkit-transform 180ms;
  transition: transform 180ms;
  transition: transform 180ms, -webkit-transform 180ms;
}

.mr-25,
.mx-25 {
  margin-right: .75rem !important;
}

.p-25 {
  padding: .75rem !important;
}

.radius-1 {
  border-radius: .25rem !important;
}

[class*=bgc-h-] {
  transition: background-color .15s;
}

.text-default-d3 {
  color: #416578 !important;
}

.font-bolder,
.text-600 {
  font-weight: 600 !important;
}

.text-90 {
  font-size: .9em !important;
}


.bgc-h-secondary-l4:hover,
.bgc-secondary-l4 {
  background-color: #f2f4f6 !important;
}

.text-danger-m1 {
  color: #da3636 !important;
}

.text-green-m1 {
  color: #2c8d6a !important;
}

.text-95 {
  font-size: .95em !important;
}

.index_page_aliment_warp {
  width: 100%;

}

.index_page_aliment_sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 10%;
  z-index: 20;
}

.index_page_aliment_table {
  position: fixed;
  top: 0rem !important;
  left: 5rem !important;
  width: 95% !important;
}

/* .element-without-scroll-bar {
  -ms-overflow-style: none;  
  scrollbar-width: none;  
}
.element-without-scroll-bar::-webkit-scrollbar { 
  display: none;  
}


.custom-bottom-shadow{
  box-shadow: 0px 32px 28px -29px rgba(0,0,0,0.45);
}

.ag-header-group-cell-label, .ag-header-cell-label{
  justify-content: center;
} */




.stock-statement-print {
  font-size: 12px;
}


/* table list view packages  */

.editListView{
  width: 25rem;
}

@media (max-width: 1366px) and (max-height: 768px) {
  .editListView {
    width: 20rem;
  }
}
