.filter-section {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

/* Wrapper for the list of sections */
.filter-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
}

/* Individual section item */
.filter-section li {
    padding: 10px;
    cursor: pointer;
    position: relative;
}

/* Blue scrollable line */
.filter-section li::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: transparent;
    transition: background-color 0.3s;
}

/* Highlight the active section */
.filter-section li.active::before {
    background-color: #058fff;
    border-radius: 50px;
}

/* Adjust the scrollable content */
.filter-content {
    margin-left: 20px;
    padding: 10px;
}

.list-information {
    background-color: #f9f9f9;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.list-information .form-group {
    margin-bottom: 15px;
}

.list-information label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.list-information .form-control {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.list-information h6 {
    font-size: 18px;
    margin: 1px 1px;
}

/* Style for the floating dots menu */
.dots-menu {
    float: right;
    cursor: pointer;
    font-size: 18px;
    color: #6c757d;
    margin-left: auto;
}

.dots-menu:hover {
    color: #000;
}

/* Custom ListGroup item with dots */
.list-item-with-dots {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 6px;
    margin-bottom: 0.5rem;
    background-color: #fff;
    transition: background-color 0.2s ease-in-out;
}


.list-item-with-dots:hover {
    background-color: #f8f9fa;
}

.list-item-with-dots::after {
    display: none !important;
    /* Removes the default down arrow */
}


.custom-toggle {
    cursor: pointer;
    font-size: 18px;
    color: #6c757d;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}


.custom-toggle:hover {
    color: #007bff;
}

.custom-toggle::after {
    display: none !important;
    /* Removes the default down arrow */
}

.dropdown-menu {
    min-width: 160px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Style for dropdown items */
.dropdown-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 14px;
    color: #333;
    transition: background-color 0.2s ease, color 0.2s ease;
}

.dropdown-item:hover {
    background-color: #f0f0f0;
    color: #000;
}

/* Icon styles inside dropdown */
.dropdown-icon {
    font-size: 16px;
    margin-right: 10px;
    color: #6c757d;
}

.dropdown-item:hover .dropdown-icon {
    color: #007bff;
}

.content {
    flex: 1;
    padding: 20px;
    height: 100vh;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
}


.no-filter {
    text-align: center;
    height: 5rem;
    font-size: 14px;
    color: #666;
    padding-top: 30px;
}

.delete-icon{
    margin-top: 22px;
    color: #ff5656d9;
    font-size: 15px;
}

.date-calender-filter{
    position: absolute;
    z-index: 1;
    width: 26rem;
    display: none;
    left: -3rem;
    top: 3.2rem;
}

.select-all-checkbox {
    margin-top: 8px;
    cursor: pointer;
  }
  
  .select-all-toggle {
    width: 14px;
    height: 20px;
    margin-right: 8px;
    cursor: pointer;
    margin-top: 8px;
  }
  
  .select-all-label {
    font-size: 14px;
    color: #333; /* Text color */
    font-weight: 500;
  }
  
  .select-all-toggle:checked {
    background-color: #4c7aaf; /* Green background when checked */
  }
  