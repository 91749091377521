.staan-input-group {
    position: relative;
  }
  
.staan-input-group .staan-form-input {
    display: block;
    background: none;
    /* background-color: #fff; */
    padding-top: .8rem;
    padding-left: 8px;
    padding-bottom: .5rem;
    width: 100%;
    height: 2.5rem;
    border-radius: 0;
    border: 1px solid rgb(206, 201, 201);
    margin-top: 15px;
    border-radius: 5px;
    font-size: 1rem;
  }

 
.staan-input-group .staan-form-input:disabled {
    background-color: #f0f0f0; /* Choose your desired background color */
    border-color: #ccc; /* Optional: change border color */
    cursor: not-allowed; /* Optional: change cursor to indicate it's disabled */
}

.pos_input_group .staan-form-input{
  font-weight: bold;
}
.staan-input-group .staan-form-input:focus {
  outline: none;
}

.staan-input-group .staan-form-input-label {
  color: black;
  font-weight: normal;
  position: absolute;
  top: 4px;
  background-color: white;
  font-size: .8rem;
}
 

.staan-input-group .staan-form-input-label.shrink {
  top: -18px;
  color: #181d2f;
}

#Item_Warranty_based{
  background-color: white;
}

.staan-error-msg{
  font-size: .7rem;
}

.staan-input-group .staan-form-input-text-area {
  display: block;
  background: none;
  /* background-color: #fff; */
  padding-top: .5rem;
  padding-left: 8px;
  padding-bottom: .5rem;
  width: 100%;
  border-radius: 0;
  border: 1px solid rgb(206, 201, 201);
  margin-top: 20px;
  border-radius: 5px;
  font-size: .8rem;
}

.staan-input-group .staan-form-input-text-area:focus {
  outline: none;
}

.rounded-icon:hover {
  border-radius: 50% !important;
  color: violet;
}


.routing-add-modal {
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  width: 50rem;
  height: 100%;
}

.routing-add-modal .modal-content{
  height: 100%;
}

.staan-input-group .staan-form-input-label-table-create-react-select {
  color: black;
  font-weight: normal;
  position: absolute;
  top: -7px;
  background-color: white;
  font-size: .7rem;
}

.staan-input-group .staan-form-input-label-table-create-react-select.shrink {
  top: -18px;
  /* left: 5px; */
  color: #181d2f;
}

/* here we handle text size */
.form-header{
  font-size: 1.5rem !important;
  color: #5CADA9 ;
  font-weight: bold;
  
}
.react-select-font input{
  font-size: 1rem !important;
  width: 110% !important;
  color:#000000 !important;
}
.text-area-input{
  font-size: 1rem !important;
  color:#000000 !important;
}

.react-select-font .css-olqui2-singleValue{
  font-size: 1rem !important;
  width: 110% !important;
  color:#000000 !important;
} 
.react-select-font .css-1dimb5e-singleValue{
  font-size: 1rem !important;
  width: 110% !important;
  color:#000000 !important;
}
.sub-heading{
  font-size: 1.2rem !important
}
.check-box-lable{
  font-size: 1rem;
  cursor: 'pointer';
  padding-top: 0;
}
.subTable-header-font{
  font-size: .9rem;
}
.button-font{
  font-size: 1rem;
}
.maxnik-text-contect{
  font-size: 1rem;
}


/* table text handeling */
.custom-table-list-view-wrap{
  height: 80vh;
}
.custom-table-list-heading{
  font-size: 1rem !important
}
.custom_table_list_view_comman_table_td{
  font-size:1rem !important
}
.custom_table_list_view_comman_table_td span{
  font-size:1rem !important
}




@media screen and (max-width: 1370px) {
  .staan-input-group .staan-form-input {
    font-size: 1rem;
  }
  .react-select-font input{
    font-size: 1rem !important;
  }
  .maxnik-text-contect{
    font-size: .8rem;
  }
  
  .sub-heading{
    font-size: 1.1rem !important
  }

  .staan-input-group .staan-form-input-label {
    font-size: .9rem;
    padding-top: 0;
  }


  .subTable-header-font{
    font-size: 1rem;
  }
  .button-font{
    font-size: 1.1rem;
  }

  .custom-table-list-view-wrap{
    height: 75vh;
  }

  .custom-table-list-heading{
    font-size: 1rem !important
  }
  .custom_table_list_view_comman_table_td{
    font-size: 1rem !important
  }
}

@media screen and (max-width: 1366px) {
  .css-1cfo1cf {
    grid-template-columns: none !important;
    /* Removes grid-template-columns */
  }
}