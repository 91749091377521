.calendar-icon {
    cursor: pointer;
    color: #555;
    margin-left: 8px;
    font-size: 18px;
    transition: color 0.3s ease;
    margin-right: 8px;
}

.calendar-icon:hover {
    color: #007bff;
}

.date-time-popup {
    position: absolute;
    top: 50px;
    left: 0;
    background: white;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 12px;
    z-index: 10;
    width: 300px;
}

.custom-time-picker {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px;
    justify-content: center;
    background: #f9f9f9;
    border-radius: 6px;
}

.clock-icon {
    cursor: pointer;
    color: #007bff;
    font-size: 20px;
}

.custom-day {
    padding: 8px;
    border-radius: 4px;
    transition: background 0.2s;
}

.custom-day:hover {
    background: #007bff;
    color: white;
}
